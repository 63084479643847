.projects {
  padding-right: 0 !important;
  background-color: #122c29;
  overflow: hidden;

  & .container {
    padding-right: 0 !important;

    @include lg-up {
      padding: 12rem 0 12rem 7.8rem !important;
    }
  
    @include xl-up {
      padding: 12rem 0 12rem 13.8rem !important;
    }
  }

  &__inner {
    margin-bottom: 5.4rem;
  }

  &__filter {
    padding-right: 3.2rem;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: calc(100% + 3.2rem);

    @include lg-up {
      width: unset;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    & ul {
      padding: 0;
    }

    &__item {
      list-style: none;
      padding: 1rem 1.6rem;
      border-radius: 2rem;
      border: #ffffff20 0.1rem solid;
      color: $white;
      transition: all 0.3s ease;

      &:hover {
        background-color: #FFFFFF1A;
        border-color: #ffffff00;
        color: $white;
      }

      &--active {
        background-color: $white !important;
        color: $black !important;
      }
    }
  }

  &__project {
    max-height: 49.4rem;
    min-height: 49.4rem;
    
    @include lg-up {
      height: 60rem;
      max-height: 60rem;
      margin-top: -0.1rem;
    }

    & .swiper {
      margin-bottom: 5.4rem;
      max-height: 54.4rem;
      min-height: 54.4rem;
      padding-bottom: 5rem;
      overflow: visible;
      
      @include lg-up {
        height: 70rem;
        max-height: 70rem;
        padding-bottom: 10rem;
        margin-top: -0.1rem;
      }

      &-button {
        &-lock {
          display: none !important;
        }
      }
    }

    & .swiper-pagination {
      top: unset;
      bottom: 1rem;
      height: 0.7rem;
      border-radius: 10rem;
      background-color: #ffffff33;
      width: calc(100% - 3.2rem);
      
      @include lg-up {
        bottom: 5.4rem;
        width: 70%;
      }
      
      @include xl-up {
        width: 70%;
      }

      &-progressbar-fill {
        border-radius: 10rem;
        background-color: $white;
      }
    }

    & .swiper-button {
      &-prev {
        bottom: 2.5rem;
        top: unset;
        border: 0.1rem solid #fff6;
        border-radius: 50%;
        width: 6.2rem;
        height: 6.2rem;
        padding: 2rem;
        display: none;
        transition: all 0.3s ease;
        left: 87%;
        
        @include lg-up {
          left: 72%;
          display: flex;
        }
        
        @include xxl-up {
          left: 73%;
        }

        &::after {
          display: none;
        }

        & svg {
          fill: $white !important;
        }

        &:hover {
          background-color: #FFFFFF1A;
          border-color: #ffffff00;
        }
      }

      &-next {
        bottom: 2.5rem;
        top: unset;
        border: 0.1rem solid #fff6;
        border-radius: 50%;
        width: 6.2rem;
        height: 6.2rem;
        padding: 2rem;
        display: none;
        transition: all 0.3s ease;
        left: 94%;
        
        @include lg-up {
          left: 80%;
          display: flex;
        }
        
        @include xl-up {
          left: 78%;
        }

        & svg {
          fill: $white !important;
        }

        &::after {
          display: none;
        }
        
        &:hover {
          background-color: #FFFFFF1A;
          border-color: #ffffff00;
        }
      }
    }

    &__slide {
      min-height: 49.4rem;
      background-color: $white;
      border-radius: 2rem;
      overflow: hidden;
      transition: all 0.3s ease;
      width: 27rem !important;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 41.82%, rgba(0, 0, 0, 0.4) 100%);
        pointer-events: none; /* Ensure the overlay doesn't block interactions with the image */
        transition: all 0.4s ease;
      }

      @include lg-up {
        min-height: 59.8rem;
        max-height: 59.8rem;
      }

      &--inner {
        transition: all 0.3s ease;
      }

      &--hide {
        display: none;
      }

      &--text {
        // transition: all 0.8s ease;
        padding: 0 1.5rem;

        & h2 {
          margin-left: 1.2rem;
          margin-top: -11.8rem;
          transition: all 0.2s ease;
          color: $white;
          font-size: 2.5rem;
          position: relative;
          z-index: 3;
        }

        & p {
          height: 0;
          opacity: 0;
          font-size: 1.6rem !important;
        }

        & span {
          height: 0;
          opacity: 0;
          // transition: height 0.8s ease;
          font-size: 1.6rem !important;
          padding: 0;
          background-color: #B1EFFA;
          border-radius: 5rem;
          position: absolute;
          bottom: 1.6rem;
        }
      }

      &--open {
        &::after {
          opacity: 0;
        }

        @include lg-up {
          width: 36rem !important;
        }

        & .projects__project__slide--inner {
          padding: 1rem;
        }

        & img {
          height: 22.5rem !important;
          transition: all 0.4s ease;
        }

        & .projects__project__slide--text {
          opacity: 1;
          height: 100%;
          overflow: scroll;
          max-height: 25rem;
          transition: all 0s ease;
          
          @include lg-up {
            max-height: 35.4rem;
          }

          & h2 {
            margin-top: 3.2rem;
            margin-left: 0;
            transition: all 0.2s ease;
            color: $black;
          }

          & p {
            opacity: 1;
            // transition: height 0.8s ease;
            height: 100%;
          }

          & span {
            opacity: 1;
            height: unset;
            padding: 1rem 1.6rem;
            // transition: height 0s ease, opacity 0.8s ease;
          }
        }
      }
    }

    & img {
      border-radius: 2rem;
      height: 49.4rem;
      width: 100%;
      object-fit: cover;
      transition: 0.3s ease;

      @include lg-up {
        height: 60rem;
        max-height: 60rem;
        margin-top: -0.1rem;
      }
    }
  }
}
