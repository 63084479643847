.hero {
  height: 100vh;

  &__headermovie {
    height: 100vh;
    object-fit: cover;

    &__volume {
      position: absolute;
      bottom: 3.2rem;
      left: 3.2rem;
      z-index: 2;
      width: 7.2rem;
      height: 7.2rem;
      background-color: $primary;
      padding: 1.2rem;
      border-radius: 50%;

      &--muted {
        & .hero__headermovie__volume--on {
          display: none;
        }

        & .hero__headermovie__volume--off {
          display: block;
        }
      }

      &--off {
        display: none;
        height: 3.9rem;
        width: 3.2rem;
      }
      
      &--on {
        display: block;
        height: 3.5rem;
        width: 3.2rem;
      }
    }
  }

  &__background {
    position: absolute;
    top: 0;

    & .image {
      max-width: unset !important;
    }

    &__image {
      height: 50vh;
      object-fit: cover;
    }

    &__icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;

      & svg {
        width: 14.2rem;
  
        @include lg-up {
          width: 16.8rem;
        }
      }
    }

    &__rect {
      height: 50vh;
    }
  }

  &__text {
    height: 100vh;
    margin-bottom: 0;
    font-size: 5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-variation-settings: 'wght' 300;

    @include lg-up {
      font-size: 7.5rem;
    }

    &--first {
      position: absolute;
      top: 15%;

      @include lg-up {
        top: 30%;
        left: 5%;
      }
    }

    &--second {
      position: absolute;
      bottom: 15%;

      @include lg-up {
        bottom: 30%;
        right: 10%;
      }
    }
  }
}
