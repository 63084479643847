.accordion {
  &__wrapper {
    background-color: #f7dcf7;
  }

  &__item {
    border-bottom: 0.1rem solid $black;
    max-height: 4.5rem;
    overflow: hidden;
    transition: max-height 0.5s ease;

    &:first-child {
      border-top: 0.1rem solid $black;
      padding-top: 2.8rem;
      max-height: 7.6rem;
    }

    & h4 {
      font-family: $font-family-base;
      font-weight: 700;
      font-size: 2.1rem;
      
      @include md-up {
        font-size: 2.3rem;
      }
    }
  }

  &__icon {
    width: 2.4rem;
    height: 2.4rem;
    position: relative;

    &--first {
      width: 2rem;
      height: 0.2rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $black;
    }

    &--second {
      width: 2rem;
      height: 0.2rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(90deg);
      transition: all 0.4s ease;
      background-color: $black;
    }
  }

  &__open {
    max-height: 100vh !important;

    & .accordion__icon--second {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  }
}
