// Normal font-family
@font-face {
  font-family: 'DMSans-Regular';
  src:
    url('./fonts/DMSans-Regular.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

// Semibold font-family
@font-face {
  font-family: 'DMSans-Semibold';
  src:
    url('./fonts/DMSans-SemiBold.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

// Light font-family
@font-face {
  font-family: 'DMSans-Light';
  src:
    url('./fonts/DMSans-Light.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}
