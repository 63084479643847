$hamburgerWidth: 36rem;

.header {
  overflow-y: visible;
  padding: 1.2rem 0;
  z-index: 99;
  width: 100%;
  transition: all 1s ease;

  &-white {
    & .header__logo {
      & svg {
        fill: $white;
      }
    }

    & .hamburger-menu__item--desktop {
      color: $white !important;
    }

    & #hamburger-icon {
      .line {
        background-color: $white !important;
      }
    }
  }

  &__inner {
    padding: 1.2rem 0;
  }

  &__logo {
    z-index: 1;

    & svg {
      fill: $black;
      transition: all 0.3s;
      height: 7.2rem !important;
    }

    &--active {
      & svg {
        fill: $white !important;
      }
    }
  }

  $height-icon: 1rem;
  $width-line: 2.85rem;
  $height-line: 0.2rem;

  $rotation: 45deg;
  $translateY: calc($height-icon / 2);
  $translateX: 0;

  #hamburger-icon {
    width: $width-line;
    height: $height-icon;

    .line {
      width: $width-line;
      height: $height-line;
      left: 0;
      border-radius: calc($height-line / 2);
      transition: all 0.3s;

      &.line-1 {
        top: 0;
      }
      &.line-2 {
        top: 100%;
      }
    }
    &:hover,
    &:focus {
      .line-1 {
        transform: translateY(calc($height-line / 2 * -1));
      }
      .line-2 {
        transform: translateY(calc($height-line / 2));
      }
    }
    &.active {
      .line-1 {
        transform: translateY($translateY) translateX($translateX)
          rotate($rotation);
        background-color: $white !important;
      }
      .line-2 {
        transform: translateY(calc($translateY * -1)) translateX($translateX)
          rotate(calc($rotation * -1));
        background-color: $white !important;
      }
    }
  }

  .hamburger {
    z-index: 1;
  }

  .hamburger-menu {
    top: 0;
    left: 0;
    transform: translateX(100%);
    width: 100%;
    transition: 0.3s transform ease-out;
    height: 100vh;
    background: #0076cb;
    padding-top: 3.2rem;

    &.active {
      transform: translateX(0) !important;

      & svg {
        fill: $white !important;
      }
    }

    a {
      svg {
        width: 1.6rem;
        height: 1.6rem;
        padding: 0.3rem;
        margin-left: 1rem;
      }
    }

    &__item {
      font-size: 3rem;

      & a {
        color: $white;

        &:hover {
          border-bottom: 0.1rem solid $white;
        }
      }

      &--desktop {
        color: $black;
        transition: all 0.3s ease;
        padding-bottom: 0.5rem !important;
        margin-bottom: 1.1rem !important;
        border-bottom: 1px solid transparent;

        &:hover {
          border-bottom: 1px solid $black;
        }
      }

      &.has-children {
        .hamburger-menu__submenu {
          top: 0;
          width: $hamburgerWidth;
          transform: translate(calc(#{$hamburgerWidth} - 3.2rem), 0);
          height: 100%;
          background: light-dark($white, $gray-800);
          color: $black;
        }
      }
    }
  }

  &.active {
    .hamburger-menu {
      transform: unset;
      transition: 0.3s transform ease-in;
    }
  }
}

.activeHeader {
  margin-top: unset;
  background-color: $white;
  padding: 0 !important;

  & .header__logo {
    & svg {
      height: 7.2rem !important;
      width: 8rem !important;
      fill: $black;
    }
  }

  & .hamburger-menu__item--desktop {
    color: $black !important;
  }

  & #hamburger-icon {
    .line {
      background-color: $black !important;
    }
  }
}

.inactiveHeader {
  margin-top: -100%;
}
