.partner {
  &__wrapper {
    background-color: #f7dcf7;
    @include lg-up {
      min-height: 60rem;
    }
  }

  &__item {
    & h3 {
      font-family: $font-family-base;
      font-weight: 700;
    }

    & a {
      color: $black;
      transition: all 0.3s ease;
      border-bottom: 1px solid transparent;

      &:hover {
        border-bottom: 1px solid $black;
        color: $black;
      }
      
      & svg {
        color: $black;
      }
    }
  }
}
