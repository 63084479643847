.sticky {
  background-color: #0076cb;
  z-index: 90;
  color: $white;
  position: absolute;
  bottom: -16.7rem;
  left: 0.7rem;
  width: calc(100% - 1.4rem);
  height: 8rem;
  border-radius: 2.4rem;
  filter: drop-shadow(11px 33px 36px #00000020);

  @include lg-up {
    bottom: -13.7rem;
    right: 4.7rem;
    width: 16.7rem;
    height: 16.7rem;
    border-radius: 50%;
    left: unset;
  }

  &--stick {
    position: fixed;
    bottom: 0.7rem;

    @include lg-up {
      // bottom: 4.7rem;
      bottom: unset;
      top: 9rem;
      right: 4.7rem;
    }
  }

  &--open {
    height: 100vh;
    width: 100%;
    bottom: 0;
    right: 0;
    border-radius: 0;
    transition: all 0.3s ease;

    & .sticky__wrapper {
      display: none;
    }
  }

  &__wrapper {
    @include lg-up {
      width: 75%;
    }
  }

  & p,
  a,
  u {
    font-size: 1.8rem;
    color: $white;

    @include lg-up {
      font-size: 1.4rem;
    }
  }
}
