.footer {
  background-color: #0076CB;
  
  @include lg-up {
    min-height: 36.9rem;
    display: flex;
    align-items: flex-end;
  }

  * {
    color: $white;
  }

  svg {
    fill: $white;
  }

  &__address {
    p {
      margin: 0;
    }

    svg {
      width: 1.4rem;
      height: 1.4rem;
    }
  }

  &__language {
    &-link {
      
      &:not(:last-child):after {
        content: " | ";
        padding: 0 10px;
        color: $white;
      }
    }
  }

  &__sub {
    background: rgba($white, 0.14);
    color: $gray-600;
    font-size: $small-font-size;
    text-align: center;
  }
}