.contact-info {
  background-color: #0076CB;
  margin-bottom: 0.3rem;

  // @include lg-up {
  //   min-height: 100vh;
  // }

  &__inner {
    animation: fade-in 0.5s ease 0.2s forwards;
    opacity: 0;
  }

  &__twint {
    &__logo {
      & svg {
        height: 4.3rem !important;
      }
    }
  }

  &__paypal {
    & svg {
      height: 2.2rem !important;
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}