.textVideo {
  background-color: #122c29;

  &__video {
    & video {
      border-radius: 2rem;
      cursor: pointer;
    }
  }

  &__text {
    & p {
      color: $white;
    }
  }
}
