.intro {
  background-color: #09302B;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  transition: all 0.4s ease;
  border-bottom: 1px solid #202020;

  &--hidden {
    transform: translateY(-100vh);
  }

  &__wrapper {
    &__logo {
      & svg {
        fill: #B1EFFA;
      }
    }

    & h2 {
      font-size: 2.8rem;

      @include lg-up {
        font-size: 4.5rem;
      }
    }
  }

  &__loading {
    height: 0.5rem;
    border: 1rem;
    width: 90%;
    background-color: #FFFFFF26;
    margin: 3.2rem;
    bottom: 3.2rem;

    &__inner {
      height: 0.5rem;
      border: 1rem;
      width: 0;
      background-color: $white;
      position: absolute;
      transition: width 0.4s cubic-bezier(1, 0.59, 0.71, 0.94);

      &::after {
        content: attr(data-value);
        display: block;
        margin-top: -2.6rem;
        font-size: 1.6rem;
        color: $white;
        text-align: right;
      }
    }
  }
}