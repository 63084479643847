// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
$font-family-sans-serif:      "DMSans-Regular", !default;
$font-family-monospace:       SFMono-Regular, 
                              Menlo, 
                              Monaco, 
                              Consolas, 
                              "Liberation Mono", 
                              "Courier New", 
                              monospace !default;
// stylelint-enable value-keyword-case

$font-family-base:            $font-family-sans-serif !default;
$font-family-code:            var(--#{$prefix}font-monospace) !default;

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root:              null !default;
$font-size-base:              1.6rem !default; // Assumes the browser default, typically `16px`
$font-size-lg-base:           2.0rem !default;
$font-size-sm:                $font-size-base * .875 !default;
$font-size-lg:                $font-size-base * 1.25 !default;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-semibold:        600 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;

$font-weight-base:            $font-weight-normal !default;

$line-height-base:            1.5 !default;
$line-height-sm:              1.25 !default;
$line-height-lg:              2 !default;

$h1-font-size:                2.8rem !default;
$h2-font-size:                2.4rem !default;
$h3-font-size:                2.1rem !default;
$h4-font-size:                1.8rem !default;
$h5-font-size:                1.6rem !default;
$h6-font-size:                1.6rem !default;

$h1-lg-font-size:             4.8rem !default;
$h2-lg-font-size:             3.6rem !default;
$h3-lg-font-size:             2.8rem !default;

// scss-docs-end font-variables

$dt-font-weight:              $font-weight-bold !default;

// scss-docs-start headings-variables
$headings-margin-bottom:      $spacer * .5 !default;
$headings-font-family:        null !default;
$headings-font-style:         null !default;
$headings-font-weight:        500 !default;
$headings-line-height:        1.2 !default;
// scss-docs-end headings-variables


$display-font-family: null !default;
$display-font-style:  null !default;
$display-font-weight: 300 !default;
$display-line-height: $headings-line-height !default;
// scss-docs-end display-headings

// scss-docs-start type-variables
$lead-font-size:              2.1rem;
$lead-font-weight:            300 !default;

$small-font-size:             .875em !default;
$sub-sup-font-size:           .75em !default;

$initialism-font-size:        $small-font-size !default;

$blockquote-margin-y:         $spacer !default;
$blockquote-font-size:        $font-size-base * 1.25 !default;
$blockquote-footer-font-size: $small-font-size !default;
