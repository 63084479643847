.notfound {
  background-color: #f7dcf7;
  height: 90vh;

  &__image {
    max-height: 32rem;
    
    @include lg-up {
      max-height: 40rem;
    }

    & img {
      object-fit: contain;
    }
  }
}