.contact-form {
  background-color: #b1effa;

  &__titellead {
    font-size: 4rem;
    line-height: 1;

    @include lg-up {
      font-size: 8rem;
      padding-bottom: 12rem;
      width: 80%;
    }
  }

  & textarea {
    border-radius: 2rem !important;
  }

  &__inputhalf {
    width: 100%;

    @include lg-up {
      width: 49%;
    }
  }

  .text-lead {
    color: $black;
  }

  &__meta {
    .icon {
      color: light-dark($black, $white);
      width: 1.6rem;
      height: 1.6rem;
      margin-right: 0.4rem;

      svg {
        fill: light-dark($black, $white);
      }
    }
  }
}
