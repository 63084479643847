.twocolumn {
  &__wrapper {
    &__logo {
      background-color: #ff7134;

      @include lg-up {
        height: 100%;
        padding: 10rem 0 !important;
      }

      & .image {
        width: unset !important;
      }

      & img {
        max-height: 20rem;
        width: unset !important;

        @include lg-up {
          max-height: 45rem;
        }
      }

      & svg {
        fill: $white;
      }
    }

    &__text {
      & h2 {
        font-size: 4.5rem;
      }
    }
  }
}
